:root {
	--border: 1px solid #f1f2f4;
	--border-2: 2px solid #f1f2f4;
	--border-half: 0.5px solid #f1f2f4;
	--color-text-green: #00af89;
	--color-text-red: #ff3b3b;
	--color-background-trade: #ffffff;
	--color-slider-rail: #f7f7f7;
	--color-slider-mark: #d8dbdf;
	--color-select-background: #f7f7f7;
	--color-select-background-selected: #e5e7ea;
	--color-button-primary-bg: #000 !important;
	--color-button-primary-bg-hover: #3a3a3c !important;
	--color-button-primary-text: #fff !important;
	--color-button-primary-border: none !important;
	--color-button-primary-disabled-text: #ccc !important;
	--dot-loader-bg: #fff !important;
	--color-button-primary-loader: #fff !important;
}

[data-theme='dark'] {
	--border: 1px solid #000;
	--border-2: 2px solid #000;
	--border-half: 0.5px solid #000;
	--color-text-green: #7affe2;
	--color-text-red: #f0565a;
	--color-background-trade: #101014;
	--color-slider-rail: #272d33;
	--color-slider-mark: #353e47;
	--color-select-background: #272d33;
	--color-select-background-selected: #3d4752;
	--dot-loader-bg: #000 !important;
	--color-button-primary-loader: #000 !important;
}

@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

button {
	appearance: none;
	outline: none;
	border: none;
}

a {
	text-decoration: none;
}

body {
	margin: 0;
	font-family: var(--font-product-sans);
}

body * {
	font-family: var(--font-product-sans) !important;
}

table {
	border-collapse: collapse;
}

.MuiPaper-elevation ul li {
	font-family: var(--font-product-sans);
	justify-content: flex-start !important;
	padding: 2px 16px !important;
	font-size: 1rem !important;
}

.MuiPaper-elevation {
	width: 120px;
}

.MuiPaper-root {
	border: 1px solid black;
	border-radius: 4px !important;
	padding: 4px;
	background-color: var(--color-select-background) !important;
	background-image: none !important;
}

.MuiPaper-elevation {
	border: none;
}

.MuiList-root {
	padding: 0 !important;
	display: flex !important;
	flex-direction: column;
	gap: 2px;
}

.MuiMenuItem-root {
	padding: 3px 7px !important;
	justify-content: center !important;
	font-size: 0.75rem !important;
	font-weight: 300 !important;
}

.MuiMenuItem-root.Mui-selected {
	background-color: var(--color-select-background-selected) !important;
	border-radius: 2px;
	font-weight: 500 !important;
	color: var(--color-text-1) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

@keyframes FADE_IN {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes FADE_OUT {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes SLIDE_IN {
	0% {
		right: -100%;
	}
	100% {
		right: 0;
	}
}

@keyframes SLIDE_OUT {
	0% {
		right: 0;
	}
	100% {
		right: -100%;
	}
}

.eCalMain {
	width: 100%;
}

@media (min-width: 992px) {
	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb {
		background: var(--color-primary);
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #f1c304;
	}
}
